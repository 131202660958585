import * as React from "react"
import Layout from "../components/Layout"
import Seo from "../components/seo"
import arrow from '../../assets/images/arrow-right.png';
import search from '../../assets/images/search.png';
import "../styles/css/styles.css"
import Search from "../components/Search/index";
import Loading from "../components/shared/Loading";

const links = [
  {
    label: "Wega",
    url: "/wega"
  },
  {
    label: "Produtos",
    url: "/produtos"
  },
  {
    label: "Catálogo",
    url: "/catalogo"
  },
  {
    label: "Assistência Técnica",
    url: "/produtos/#"
  },
  {
    label: "Dicas Técnicas",
    url: "/dicas-tecnicas"
  },
  {
    label: "Contato",
    url: "/contato"
  }
]

const NotFoundPage = () => {
  
  const [ loading, setLoading ] = React.useState(false)
  
  React.useLayoutEffect(() => {
    setTimeout(() => {
      setLoading(true)
    }, 2000)
  })

  if(loading == false) return <Loading />

  return(
    <div className="error-not-found-container">
      <Layout>
        <div className="error-not-found-main-title">
          <h1 className="title">Ops, esta página não pode ser encontrada! 404</h1>
        </div>

        <div className="main-links">
          <div className="main-link-container">
            <div>
              <h2 className="title-2">404</h2>
            </div>
            <div className="links">
              <p className="paragraph-title">Links úteis</p>
              <ul>
                {links.map(link => (
                  <li>
                    <span><img src={arrow} /></span>
                    <a className="link" href={link.url}>{link.label}</a>
                  </li>
                ))}
              </ul>
              {/* <form className="container-search">
                <h3 className="title">Busca no site</h3>
                <p className="content-search">Não encontrou o que precisa? Faça uma busca abaixo!</p>
                <div className="input-btn-actions">
                  <input className="search-input" type="text" name="search" placeholder="Pesquisar..." />
                  <button className="search-button"><img src={search} /></button>
                </div>
              </form> */}
            </div>
          </div>
        </div>
        <Search></Search>
      </Layout>
    </div>
  )
}

export const Head = () => <Seo title="" />

export default NotFoundPage
